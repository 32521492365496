<template>
  <div>
    <content-loader :height="280" :width="400" v-if="type === 'poll' || type === 'generic' || type === 'items'">
      <rect :x="(size + gap) * (inx - 1)" y="0" :width="size" :height="size" v-for="(item, inx) in 6" :key="inx"/>

      <rect x="0" y="80" width="400" height="200" />
    </content-loader>

    <content-loader :height="280" :width="400" v-if="type === 'search-result'">
      <rect :x="0" :y="(size + gap) * (inx - 1)" :width="400" :height="size" v-for="(item, inx) in 5" :key="inx"/>
    </content-loader>

    <content-loader :height="400" :width="400" v-if="type === 'product'">
      <rect x="0" y="10" rx="2" ry="2" width="400" height="20" />
      <rect x="0" y="35" rx="2" ry="2" width="400" height="20" />
      <rect x="0" y="60" rx="2" ry="2" width="400" height="40" />
      <rect x="0" y="110" rx="2" ry="2" width="400" height="200" />
      <rect x="0" y="320" rx="2" ry="2" width="400" height="80" />
    </content-loader>

    <content-loader :height="800" :width="400" v-if="type === 'app'">
      <rect x="50" y="9.61" rx="3" ry="3" width="40" height="800" />
      <rect x="130" y="9.61" rx="3" ry="3" width="40" height="800" />
      <rect x="210" y="7.61" rx="3" ry="3" width="40" height="800" />
      <rect x="290" y="7.61" rx="3" ry="3" width="40" height="800" />
    </content-loader>

    <content-loader :height="200" :width="400" v-if="type === 'banner'">
      <rect x="0" y="0" rx="3" ry="3" width="400" height="40" />
      <rect x="0" y="50" rx="3" ry="3" width="400" height="40" />
      <rect x="0" y="100" rx="3" ry="3" width="400" height="40" />
      <rect x="0" y="150" rx="3" ry="3" width="400" height="40" />
    </content-loader>

    <content-loader :height="24" :width="400" v-if="type === 'search-tab'">
      <rect x="5" y="0" rx="10" ry="10" width="93.75" height="18" />
      <rect x="103.75" y="0" rx="10" ry="10" width="93.75" height="18" />
      <rect x="202.5" y="0" rx="10" ry="10" width="93.75" height="18" />
      <rect x="301.25" y="0" rx="10" ry="10" width="93.75" height="18" />
    </content-loader>

    <content-loader :height="400" :width="400" v-if="type === 'deal'">
      <rect x="15" y="20" rx="3" ry="3" width="88.75" height="120" />
      <rect x="108.75" y="20" rx="3" ry="3" width="88.75" height="120" />
      <rect x="202.5" y="20" rx="3" ry="3" width="88.75" height="120" />
      <rect x="296.25" y="20" rx="3" ry="3" width="88.75" height="120" />

      <rect x="10" y="160" rx="3" ry="3" width="380" height="40" />

      <rect x="10" y="220" rx="3" ry="3" width="250" height="160" />
      <rect x="270" y="220" rx="3" ry="3" width="120" height="77.5" />
      <rect x="270" y="302.5" rx="3" ry="3" width="120" height="77.5" />
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'ContentLoading',
  components: {
    ContentLoader,
  },
  props: {
    type: {
      type: String,
      default: 'generic',
    },
  },
  data() {
    return {
      gap: (400 - 70 * 5) / 4,
      size: 70,
    };
  },
};
</script>
